import React, { useState, useEffect } from 'react';

import { IngredientItem, IngredientItemEditor, InHouseIngredientItemEditor } from 'models/entities/ingredient-item';
import { Currency } from 'models/value-objects/currency';
import { Model } from './index.model';

import { useAuth } from 'views/services/auth';
import { useMemory } from 'views/services/memory';
import { useBusinessCode } from 'views/services/hooks';
import { useModal, EditPurchasedIngredientItemTarget, EditInHouseIngredientItemTarget, ModalResult } from 'views/services/modal';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { ContentFrame } from 'views/components/content-frame';
import { showPricePerPound, showUnit, showRate, showMoney } from 'views/services/helpers';
import { Header } from 'views/components/header';
import { Root, List } from './index.styled';

type DataToEdit = {
  ingredientItem: IngredientItemEditor;
  currency: Currency;
};

const IngredientItems = () => {

  const auth = useAuth();
  const memory = useMemory();
  const code = useBusinessCode();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function edit(item?: IngredientItem) {
    if (!model.business) throw new Error('business is undefined');
    const ingredientItem = model.buildEditor(item);
    const currency = model.business.currency;
    const data = { ingredientItem, currency };
    ingredientItem instanceof InHouseIngredientItemEditor ? editInHouseIngredientItem(data) : editPurchasedIngredientItem(data);
  }

  function editPurchasedIngredientItem(data: DataToEdit) {
    const target = new EditPurchasedIngredientItemTarget(data);
    const tell = applyPurchasedIngredientItem;
    modal.open('edit-purchased-ingredient-item', { target, strict: true, tell });
  }

  function applyPurchasedIngredientItem(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof EditPurchasedIngredientItemTarget)) return;
    const item = result.target.ingredientItem.original;
    switch (result.state) {
      case 'created': setModel(model.add(item)); break;
      case 'updated': setModel(model.replace(item)); break;
      case 'deleted': setModel(model.remove(item)); break;
    }
  }

  function editInHouseIngredientItem(data: DataToEdit) {
    const target = new EditInHouseIngredientItemTarget(data);
    const tell = applyInHouseIngredientItem;
    modal.open('edit-in-house-ingredient-item', { target, strict: true, tell });
  }

  function applyInHouseIngredientItem(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof EditInHouseIngredientItemTarget)) return;
    const item = result.target.ingredientItem.original;
    switch (result.state) {
      case 'updated': setModel(model.replace(item)); break;
      case 'deleted': setModel(model.remove(item)); break;
    }
  }

  function importData() {
    const target: IngredientItem[] = [];
    const other = { business: model.business };
    const tell = applyItems;
    modal.open('import-ingredient-items', { target, strict: true, other, tell });
  }

  function applyItems(result: ModalResult) {
    if (!result.target) return;
    const items = result.target as IngredientItem[];
    switch (result.state) {
      case 'imported': setModel(model.addBatch(items)); break;
    }
  }

  function exportData() {
    const target = model.list;
    modal.open('export-ingredient-items', { target });
  }

  function exportCategories() {
    const target = model.business?.ingredientCategories;
    modal.open('export-ingredient-categories', { target });
  }

  function watchAuth() {
    if (!auth.ok) return;
    if (!code) return;
    const businessScope = memory.get('business-scope');
    businessScope ? setModel(model.init(businessScope)) : model.readBusiness(code).then(model => setModel(model));
  }

  function watchBusiness() {
    if (!model.business) return;
    model.read().then(model => setModel(model));
  }

  function watchCollection() {
    if (!model.collection) return;
    if (!model.collection.nextToken) return;
    model.readAllCollection().then(it => setModel(it));
  }

  function watchSuppliers() {
    if (!model.suppliers) return;
    if (!model.suppliers.nextToken) return;
    model.readAllSuppliers().then(it => setModel(it));
  }

  function watchCategories() {
    if (!model.categories) return;
    if (!model.categories.nextToken) return;
    model.readAllCategories().then(it => setModel(it));
  }

  useEffect(watchAuth, [auth.ok, code]);
  useEffect(watchBusiness, [model.business]);
  useEffect(watchCollection, [model.collection]);
  useEffect(watchSuppliers, [model.suppliers]);
  useEffect(watchCategories, [model.categories]);

  return (
    <Root>
      {auth.user.admin ? (
        <Header mode={'business'} businesses={model.businesses} business={model.business} onCreate={() => edit()} disabledAction={model.disabledAction} onImport={() => importData()} onExport={() => exportData()} onExportCategories={() => exportCategories()} />
      ) : (
        <Header mode={'business'} businesses={model.businesses} business={model.business} onCreate={() => edit()} disabledAction={model.disabledAction} />
      )}
      {!model.collection && <InterfaceLoading />}
      {model.list && (
        <ContentFrame>
          {!model.list.length ? <NoInterfaceData>No Ingredient Item</NoInterfaceData> : (
            <List>
              <table>
                <thead>
                <tr>
                  <th>Category</th>
                  <th>Name</th>
                  <th>Supplier</th>
                  <th>Pound</th>
                  <th>Unit</th>
                  <th>Net Price</th>
                  <th>Yield Rate</th>
                  <th>Gross Price</th>
                </tr>
                </thead>
                <tbody>
                {model.list && model.list.map((it, i) => (
                  <tr key={i} onClick={() => edit(it)}>
                    <td>{it.category.original.name}</td>
                    <td>{it.name}</td>
                    <td>{it.supplier?.name}</td>
                    <td className="right">{showPricePerPound(it.poundPrice!, model.business?.currency)}</td>
                    <td className="right">{showUnit(it.unitValue!, it.unitSymbol!)}</td>
                    <td className="number">{showMoney(it.netPrice!, model.business?.currency)}</td>
                    <td className="number">{showRate(it.yieldRate!)}</td>
                    <td className="number">{showMoney(it.grossPrice!, model.business?.currency)}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </List>
          )}
        </ContentFrame>
      )}
    </Root>
  );

};

export default IngredientItems;