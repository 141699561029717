import { IngredientItem, IngredientItemCollection, IngredientItemFactory, IngredientItemEditor } from 'models/entities/ingredient-item';
import { Business, BusinessCollection } from 'models/entities/business';
import { SupplierCollection } from 'models/entities/supplier';
import { IngredientCategoryCollection } from 'models/entities/ingredient-category';

import { Condition } from './models/condition';
import { ReadBusinessGql, BusinessDataToInit } from '../index.model';
import { ReadGql } from './models/read-gql';

class Model {

  readonly condition: Condition;
  readonly collection?: IngredientItemCollection;
  readonly list?: IngredientItem[];
  readonly business?: Business;
  readonly businesses?: BusinessCollection;
  readonly suppliers?: SupplierCollection;
  readonly categories?: IngredientCategoryCollection;
  readonly disabledAction: {
    onCreate: boolean;
    onImport: boolean;
    onExport: boolean;
    onExportCategories: boolean;
  };

  constructor() {
    this.condition = new Condition();
    this.disabledAction = {
      onCreate: false,
      onImport: false,
      onExport: false,
      onExportCategories: false,
    };
  }

  init(data: BusinessDataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  async readBusiness(code: string): Promise<this> {
    const gql = await new ReadBusinessGql().fetch({ code });
    const { business, businesses } = gql;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, business, businesses });
  }

  async read(): Promise<this> {
    if (!this.business) throw new Error('business is undefined');
    const gql = await new ReadGql().fetch({ businessId: this.business.id! });
    if (!gql.result) throw new Error('invalid result');
    const collection = new IngredientItemCollection(this.business.id, gql.result.collection);
    const list = this.condition.filter(collection.documents);
    const suppliers = new SupplierCollection(this.business.id, gql.result.suppliers);
    const categories = this.business.ingredientCategories;
    const disabledAction = {
      onCreate: !suppliers.documents.length || !categories.documents.length,
      onImport: !suppliers.documents.length || !categories.documents.length,
      onExport: !list.length,
      onExportCategories: !categories.documents.length,
    };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list, suppliers, categories, disabledAction });
  }

  async readAllCollection(): Promise<this> {
    if (!this.collection) throw new Error('collection is undefined');
    const collection = await this.collection.readAll();
    const list = this.condition.filter(collection.documents);
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list });
  }

  async readAllSuppliers(): Promise<this> {
    if (!this.suppliers) throw new Error('suppliers is undefined');
    const suppliers = await this.suppliers.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, suppliers });
  }

  async readAllCategories(): Promise<this> {
    if (!this.categories) throw new Error('categories is undefined');
    const categories = await this.categories.readAll();
    return Object.assign(Object.create(this.constructor.prototype), { ...this, categories });
  }

  build(): IngredientItem {
    if (!this.collection) throw new Error('collection is undefined');
    if (!this.business) throw new Error('cache is undefined');
    if (!this.suppliers || !this.suppliers.documents.length) throw new Error('suppliers are undefined or empty');
    if (!this.categories || !this.categories.documents.length) throw new Error('categories are undefined or empty');
    return IngredientItemFactory.create({ businessId: this.business.id!, supplier: this.suppliers.documents[0], category: this.categories.documents[0] });
  }

  buildEditor(item?: IngredientItem): IngredientItemEditor {
    if (!this.suppliers) throw new Error('suppliers are undefined');
    if (!this.categories) throw new Error('categories are undefined');
    const original = item ?? this.build();
    const suppliers = this.suppliers;
    const categories = this.categories;
    const unitSymbols = this.collection?.getUnitSymbols() || [];
    return IngredientItemFactory.createEditor({ original, suppliers, categories, unitSymbols });
  }

  add(item: IngredientItem): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.add(item));
  }

  addBatch(items: IngredientItem[]): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.addBatch(items));
  }

  replace(item: IngredientItem): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.replace(item));
  }

  remove(item: IngredientItem): this {
    if (!this.collection) throw new Error('collection is undefined');
    return this.apply(this.collection.remove(item));
  }

  private apply(collection: IngredientItemCollection): this {
    const list = this.condition.filter(collection.documents);
    const disabledAction = this.disabledAction;
    disabledAction.onExport = !list.length;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, collection, list, disabledAction });
  }

}

export { Model };