import { Api } from 'models/services/api';
import { ReadIngredientItemsQuery, ReadSuppliersQuery } from 'models/services/graphql';
import { IngredientItemCollectionData } from 'models/entities/ingredient-item';
import { SupplierCollectionData } from 'models/entities/supplier';

type DataToRead = {
  businessId: string;
};

const RequestQuery = `
  query Ingredients($businessId: ID!, $nextToken: String) {
    ${ReadIngredientItemsQuery}
    ${ReadSuppliersQuery}
  }
`;

type ResponseData = {
  data: {
    readIngredientItems: IngredientItemCollectionData;
    readSuppliers: SupplierCollectionData;
  }
};

type Result = {
  collection: IngredientItemCollectionData;
  suppliers: SupplierCollectionData;
};

class ReadGql {

  readonly result?: Result;
  private readonly api: Api<ResponseData>;

  constructor() {
    this.api = new Api<ResponseData>(RequestQuery);
  }

  async fetch(data: DataToRead): Promise<this> {
    const api = await this.api.fetch({ variables: data });
    if (!api.response) throw new Error('invalid response');
    const collection = api.response.data.readIngredientItems;
    const suppliers = api.response.data.readSuppliers;
    const result = { collection, suppliers };
    return Object.assign(Object.create(this.constructor.prototype), { ...this, result, api });
  }

}

export { ReadGql };