import { IngredientItem } from 'models/entities/ingredient-item';

class Condition {

  constructor() {
  }

  filter(all: IngredientItem[]): IngredientItem[] {
    return this.sort(all.filter(it => this.check(it)));
  }

  sort(all: IngredientItem[]): IngredientItem[] {
    const byCategory = (a: IngredientItem, b: IngredientItem) => {
      switch (true) {
        case a.category.order.toString() < b.category.order.toString(): return -1;
        case a.category.order.toString() > b.category.order.toString(): return 1;
        case a.category.order.toString() === b.category.order.toString() && a.name < b.name: return -1;
        case a.category.order.toString() === b.category.order.toString() && a.name > b.name: return 1;
        default: return 0;
      }
    };
    return all.sort(byCategory);
  }

  private check(item: IngredientItem): boolean {
    return true;
  }

}

export { Condition };