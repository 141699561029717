import styled from 'styled-components';

import { baseFontFamily, baseFontSize, baseLineHeight, numberFontFamily, numberFontSize } from 'views/services/font-scheme';
import { baseColor, textColor, lineColor, lightPrimaryColor, darkPrimaryColor, vividPrimaryColor, primaryTextColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

export const Root = styled.div`
`;

export const List = styled.div`
  & > table {
    width: 100%;
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    line-height: ${baseLineHeight};
    background-color: ${baseColor};
    color: ${textColor};
    border-collapse: collapse;
    & > thead {
      z-index: 10;
      position: sticky;
      top: 0;
    }
    & > thead > tr > th {
      height: ${headerHeight};
      text-align: left;
      vertical-align: middle;
      font-weight: bold;
      padding: 0 16px;
      background-color: ${darkPrimaryColor};
      color: ${primaryTextColor};
      white-space: nowrap;
      &:nth-child(1) {
        width: 12%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 7%;
      }
      &:nth-child(5) {
        width: 7%;
      }
      &:nth-child(6) {
        width: 8%;
      }
      &:nth-child(7) {
        width: 8%;
      }
      &:nth-child(8) {
        width: 8%;
      }
    }
    & > tbody > tr {
      &:hover {
        cursor: pointer;
        background-color: ${lightPrimaryColor};
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: -0.5px;
          left: 0;
          right: 0;
          bottom: -0.5px;
          border-top: 1px solid ${vividPrimaryColor};
          border-bottom: 1px solid ${vividPrimaryColor};
        }
      }
    }
    & > tbody > tr > td {
      border-top: 1px solid ${lineColor};
      border-bottom: 1px solid ${lineColor};
      padding: 8px 16px;
      white-space: nowrap;
      &.right {
        text-align: right;
      }
      &.number {
        font-family: ${numberFontFamily};
        font-size: calc(${numberFontSize} * 1);
        text-align: right;
      }
    }
  }
`;
