import React, { FC, PropsWithChildren, UIEvent } from 'react';

import { Root } from './index.styled';

interface Props {
  onReachBottom?: () => void;
}

const ContentFrame: FC<Props & PropsWithChildren> = ({ onReachBottom, children }) => {

  function handleScroll(e: UIEvent<HTMLDivElement>) {
    const scrollTop = e.currentTarget.scrollTop;
    const root = e.currentTarget.getBoundingClientRect();
    const child = e.currentTarget.children[0].getBoundingClientRect();
    const scrollYRate = (scrollTop + root.height) / child.height;
    if (scrollYRate > 0.8) onReachBottom && onReachBottom();
  }

  return (
    <Root onScroll={handleScroll}>
      {children}
    </Root>
  );

};

export { ContentFrame };