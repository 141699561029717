import styled from 'styled-components';

import { darkBaseColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

export const Root = styled.div`
  width: 100vw;
  height: calc(100vh - ${headerHeight});
  overflow: scroll;
  background-color: ${darkBaseColor};
`;